import { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

function AppNav() {
    
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('auth');
        navigate('/');
    };

    return (
    <div className="flex">
        <nav className={`bg-theme-500 p-4 md:max-w-56 min-h-screen ${isOpen ? 'block' : 'hidden'} md:block`}>
            <div className="text-center text-white text-xl mb-4 font-bold">
                <Link to="/">CFK Holdings Pty Ltd</Link>
            </div>
            <div className="flex flex-col space-y-4 text-center flex-grow">
                <Link to="/" className="text-white hover:bg-theme-400 p-2 rounded">Configuration</Link>
                <Link to="/telemetry" className="text-white hover:bg-theme-400 p-2 rounded">Telemetry</Link>
                <button className="text-white hover:bg-red-500 p-2 rounded" onClick={logout}>Logout</button>
            </div>
        </nav>
        <div className="flex-1 p-4">
            <button onClick={() => setIsOpen(!isOpen)} className="md:hidden mb-4 text-white bg-tgene-500 p-2 rounded">
                {isOpen ? 'Close Menu' : 'Open Menu'}
            </button>
        </div>
    </div>
    );
}

export default AppNav;
