export function WarnModal(params) {
    const { message, visible, setVisible } = params;

    return (
        <div className={'fixed left-0 top-0 h-screen w-screen bg-gray-900/80 flex items-center text-center' + (visible ? '' : ' hidden')}>
            <div className="w-full">
                <h1 className="text-2xl">Important Message</h1>
                <p>{message}</p>
                <button className="bg-orange-500 p-1 px-2 rounded mx-2" onClick={()=>setVisible(false)}>Okay</button>
            </div>
        </div>
    );
}

export function ProceedModal(params) {
    const { message, visible, proceedFunc, setVisible } = params;

    return (
        <div className={'fixed left-0 top-0 h-screen w-screen bg-gray-900/80 flex items-center text-center' + (visible ? '' : ' hidden')}>
            <div className="w-full">
                <h1 className="text-2xl">Important Message</h1>
                <p>{message}</p>
                <button className="bg-red-500 p-1 px-2 rounded mx-2" onClick={()=>setVisible(false)}>Cancel</button>
                <button className="bg-sky-500 p-1 px-2 rounded mx-2" onClick={() => {proceedFunc();setVisible(false);}}>Proceed</button>
            </div>
        </div>
    );
}
