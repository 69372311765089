import { useState, useEffect } from 'react';
import { attemptLogin } from '../api.js'

import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    
    useEffect(() => {if(localStorage.getItem('auth') !== null)navigate("/config")}, [navigate]); 
    if(localStorage.getItem('auth') !== null) {
        return <></>;
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        const auth = {'email': email, 'password': password};
        attemptLogin(auth).then((data) => {
            if('err' in data) {
                toast.error('Invalid Credentials', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "coloured",
                });
                return;
            } else if('client_err' in data) {
                toast.error('Client Error', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "coloured",
                });
                console.log("We probably couldn't connect to the server sorry :(");
                return;
            } else if(!('auth' in data)) {
                toast.error('Unknown Error', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "coloured",
                });
                console.log("You've received a weird error... please contact us!");
                return;
            }

            localStorage.setItem('auth', data.auth);
            navigate('/config');
        }); 
    }; 

    return (
        <div className="flex items-center justify-center h-full">
            <div className="bg-theme-400 text-white p-3 rounded-2xl">
                <h2 className="text-3xl font-bold text-center m-2">Login</h2>
                <form onSubmit={handleLogin} className="flex flex-col">
                    <input className="bg-theme-500 text-center block m-2 text-2xl rounded-md p-1 placeholder:text-theme-300 focus:outline-theme-300 focus:outline-offset-1 focus:outline" type="text" placeholder="joe@example.org" onChange={(e) => setEmail(e.target.value)} value={email} required />
                    <input className="bg-theme-500 text-center block m-2 text-2xl rounded-md p-1 placeholder:text-theme-300 focus:outline-theme-300 focus:outline-offset-1 focus:outline" type="password" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" onChange={(e) => setPassword(e.target.value)} value={password} required/>
                    <input type="submit" className="bg-theme-300 hover:bg-theme-200 text-center block m-2 text-2xl rounded-md p-1" value="Login" />
                </form>
            </div>
        </div>
    );
}

export default Login;
