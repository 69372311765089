import { useEffect, useState } from 'react';
import AppNav from '../nav/Navigation.js';
import { getBotList, getBotConfig, checkAuth } from '../api.js'; // need to have an API class instead probably so state and api calls aren't mixed up
import GeneralInformation from './GeneralInformation.js';
import TreeEditor from './TreeEditor.js';

import { useNavigate } from 'react-router-dom';

function BotConfiguration() {
    const [botList, setBotList] = useState([]);
    const [selectedBot, setSelectedBot] = useState('');
    const [botConfig, setBotConfig] = useState({});
    const [code, setCode] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        if(!checkAuth()) {
            navigate("/");
            return;
        }

        getBotList().then((res) => {
            console.log(res);
            if(res['err'] !== undefined || res['client_err'] !== undefined) {
                localStorage.removeItem('auth');
                console.log("Failed to connect, this could be due to the API service restarting or the API service is online. Please check the status of the API.");
                navigate("/");
                return;
            }

            setBotList(res['bots']);

            if(res['bots'].length > 0) {
                for(var i = 0; i < res['bots'].length; i++) {
                    const b = res['bots'][i];
                    getBotConfig(b).then((res) => {
                        if(res['current'] === true) {
                            setSelectedBot(b);
                        }
                    });
                }
            }
        });
    }, [navigate]);

    useEffect(() => {
        getBotConfig(selectedBot).then((res) => {
            setBotConfig(res);
        });
    }, [selectedBot]);

    return (
        <div className="bg-theme-600 min-h-screen flex font-mono">
            <div className="sticky top-0 h-screen">
                <AppNav />
            </div>

            <div className="p-4 pr-10 w-full overflow-y-visible">
                <h2 className="text-4xl text-white my-2">Configuration Management</h2>
                {
                    botConfig === undefined || botList === undefined || Object.keys(botConfig).length === 0 ?
                        <></>
                    :
                        <>
                            <GeneralInformation botConfig={botConfig} botList={botList} setVersion={setSelectedBot} setBotConfig={setBotConfig} code={code} setCode={setCode}/>
                            <TreeEditor botConfig={botConfig} setBotConfig={setBotConfig} code={code} />
                        </>
                }
            </div>
        </div>
    );
}

export default BotConfiguration;
