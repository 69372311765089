import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import BotConfiguration from './config/BotConfiguration.js'
import Telemetry from './telemetry/Telemetry.js'
import Login from './login/Login.js';

function Provider() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Login />
        },
        {
            path: "/config",
            element: <BotConfiguration />,
        },
        {
            path: "/telemetry",
            element: <Telemetry />
        }
    ]);

    return <RouterProvider router={router} />;
}

export default Provider;
