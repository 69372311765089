const debugApi = 'http://localhost:5000'; // dev api
const stagingApi = 'https://api-staging.agius.dev';
const apiUrl = () => { if(process.env.NODE_ENV === 'development') { return debugApi; } else { return stagingApi; } }

export async function attemptLogin(auth) {
    const res = await fetch(apiUrl() + '/auth', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(auth)
    }).then((result) => result.json()).catch((error) => {return {'client_err': error.message};});

    return res;
}

export async function getTelemetry() {
    const res = await fetch(apiUrl() + '/telemetry', {
        method: 'GET',
        headers: {
            "Authorization": localStorage.getItem('auth')
        }
    }).then((result) => result.json()).catch((error) => {return {'client_err': error.message};});

    return res
}

export function checkAuth() {
    const auth = localStorage.getItem('auth');
    if(auth === null || auth === undefined) {
        return false;
    }
    return auth;
}

export async function getBotList() {
    const res = await fetch(apiUrl() + '/bot?list=true', {
        method: 'GET',
        headers: {
            "Authorization": localStorage.getItem('auth')
        }
    }).then((result) => result.json()).catch((error) => {return {'client_err': error.message};});

    return res;
}

export async function saveBot(bot, botList) {
    var cfg = {...bot};
    cfg['date'] = new Date().toLocaleString();
    cfg['current'] = false;
    for(var i = 0; i < 99999; i++) // WARNING: After 99,999 versions this needs to be changed hopefuly by then we've added named versions
    {
        if(botList.includes("v"+i))
            continue;
        cfg['_id'] = 'v'+i;
        break;
    }

    const res = await fetch(apiUrl() + '/bot', {
        method: 'POST',
        headers: {
            "Authorization": localStorage.getItem('auth'),
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cfg)
    }).then((result) => result.json()).catch((error) => {return {'client_err': error.message};});

    return res;
}

export async function getBotConfig(version) {
    const res = await fetch(apiUrl() + '/bot?ver=' + version, {
        method: 'GET',
        headers: {
            "Authorization": localStorage.getItem('auth')
        }
    }).then((result) => result.json()).catch((error) => {return {'client_err': error.message};});

    return res;
}

export async function updateCurrent(version) {
    const res = await fetch(apiUrl() + '/bot/current', {
        method: 'POST',
        headers: {
            "Authorization": localStorage.getItem('auth'),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({"version": version})
    }).then((result) => result.json()).catch((error) => {return {'client_err': error.message};});

    return res;
}
