import { ProceedModal, WarnModal } from '../modal.js';
import { updateCurrent, saveBot } from '../api.js';

import { useState } from 'react';



function GeneralInformation(params) {
    //Parameter Variables
    const { botConfig, botList, setVersion, setBotConfig, code, setCode } = params;

    //Modal Controllers
    const [changeCurrentModal, setChangeCurrentModal] = useState(false);
    const [warnMessage, setWarnMessage] = useState('');
    const [warnModal, setWarnModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);

    const { tree } = botConfig;

    const handleVersionChange = (ev) => {
        setVersion(ev.target.value);
    };

    const toggleOptionMod = (ev) => {
        const targ = ev.target.getAttribute("name");
        const name = targ.substring(0, targ.indexOf("-"));
        const indexEdit = parseInt(targ.substring(targ.indexOf("-")+1));

        botConfig['start']['options'][indexEdit][name] = !(botConfig['start']['options'][indexEdit][name]);
        setBotConfig((prev) => ({...prev, start: botConfig['start']}));
    };

    const handleCurrentChange = (current) => {
        if(current === true) {
            setWarnMessage("You cannot remove the current config, you can only change the current config by switching to another version. i.e, change to another version then click the checkbox!");
            setWarnModal(true);
            return;
        }

        setChangeCurrentModal(true);
    };

    //State Updates
    const updateAuthors = (ev) => {
        //const auths = ev.target.value.split(',').map(str => str.trim());
        setBotConfig((prev) => ({...prev, authors: [ev.target.value]}));
    };

    const updateQuestion = (ev) => {
        setBotConfig((prev) => ({...prev, start: {question: ev.target.value, options: prev.start.options}}));
    };

    const updateStartOptions = (ev) => {
        const key = ev.target.name.substring(ev.target.name.indexOf('-')+1);
        const value = ev.target.value;
        if(value.includes('-')) // TODO: WARNING: This is because we parse the name new feature potential custom elements
        {
            return;
        }
        const newName = value.toLowerCase().replaceAll(' ', '_');

        const newOptions = botConfig.start.options.map((item) => {
            if(item['leads'] === key) {
                return {'text': value, 'leads': newName}
            }
            return item;
        });
        console.log('update');
        // Can't use index need to use old key name
        let newTree = {};
        Object.keys(tree).forEach((treeKey) => {
            if(treeKey === key)
                newTree[newName] = tree[treeKey];
            else
                newTree[treeKey] = tree[treeKey];
        });
        setBotConfig((prev) => ({...prev, start: {question: prev.start.question, options: newOptions}, tree: newTree}))
    }

    const newStartOption = (ev) => {
        const node = {question: '', options: [], prompt: '', subtree: {}};
        const keys = Object.keys(botConfig.tree);
        for(let i = 1; i < 99; i++) {
            if(keys.includes("node_"+i))
                continue;

            const newTree = botConfig.tree;
            newTree["node_" + i] = node;

            let nodeIndex = i; // Clear semantics.
            setBotConfig((prev) => ({
                ...prev, 
                start: {
                    question: prev.start.question,
                    options: [...prev.start.options, {'text': "Node " + nodeIndex, 'leads': "node_"+nodeIndex}],
                }, 
                tree: {...prev.tree}
            }));
            break;
        }
    }

    const delParent = (ev) => {
        const name = ev.target.name.substring(0, ev.target.name.indexOf("-"));
        const indexEdit = parseInt(ev.target.name.substring(ev.target.name.indexOf("-")+1));
        delete tree[name];
        botConfig['start']['options'].splice(indexEdit, 1);
        console.log(botConfig.start.options);
        setBotConfig((prev) => ({...prev, start: {...prev.start, options: botConfig['start']['options']}, tree: tree}));
    };

    const toggleCode = () => {

        setCode(!code);
    };

    return (
        <div className="text-theme-100">
            <h2 className="text-2xl my-2">General Information</h2>
            <div className="flex items-center space-x-3">
                <label htmlFor="_id">Version:</label>
                <select className="bg-theme-400 p-1" name="_id" value={botConfig._id} onChange={handleVersionChange}>
                    {botList.map((option) => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
                <label htmlFor="current">Current:</label>
                <input className="hidden" type="checkbox" name="current" />
                <label htmlFor="current" className="w-5 h-5 bg-theme-400 text-center" onClick={() => handleCurrentChange(botConfig.current)}>{botConfig.current ? '✔' : ''}</label>
                <label htmlFor="date">Date:</label>
                <input className="px-1 bg-gray-400 text-black" type="text" name="date" value={botConfig.date} disabled />
                <label htmlFor="authors">Authors:</label>
                <input className="px-1 bg-theme-400 flex-grow" type="text" name="authors" value={botConfig.authors} onChange={updateAuthors} />
                <button className="bg-sky-600 px-4 pt-1" onClick={()=>{setSaveModal(true);}}>Save</button>
                <button className="bg-orange-300 px-4 pt-1" onClick={toggleCode}>Code</button>
            </div>
            <br />
        { code ? 
            <textarea rows="15" className="resize-none block w-full px-1 my-2 bg-theme-400" value={JSON.stringify(botConfig, null, 4)}></textarea>
            : <>
            <label htmlFor="introduction">Introduction</label>
            <textarea rows="3" className="resize-none block w-full px-1 my-2 bg-theme-400" value={botConfig.start.question} onChange={updateQuestion}></textarea>
            <label>Response Options</label>
            <div className="ml-10">
                {botConfig.start.options.map((resOption, index) => (
                    <div className="flex space-x-4 my-2">
                        <label htmlFor={'text-'+resOption.leads}>Text</label>
                        <input className="px-1 bg-theme-400 w-1/3" type="text" name={'text-' +resOption.leads} value={resOption.text} onChange={updateStartOptions} />
                        {
                            resOption['freedom'] === true ? <></> :
                            <>
                                <label htmlFor={'leads-'+resOption.leads}>Leads</label>
                                <input className="px-1 bg-gray-400 text-black" type="text" name={'leads-'+resOption.leads} value={resOption.leads} disabled />
                            </>
                        }
                        
                        <>
                            <label name={'freedom-'+index}>Freedom</label>
                            <label name={'freedom-'+index} className="w-5 h-5 bg-theme-400 text-center" onClick={toggleOptionMod}>
                                {resOption['freedom'] !== undefined && resOption['freedom'] === true ? '✔' : ''}
                            </label>
                        </>
                        <button className="px-4 bg-red-800 hover:bg-red-900" onClick={delParent} name={resOption.leads +'-'+index}>Del Parent</button>
                    </div>
                ))}
                <button className="bg-emerald-600 px-4 pt-1" onClick={newStartOption}>Add Parent</button>
            </div>
        </>}
            <ProceedModal message="This will update the current customer chatbot interface and chats following this change will follow the configuration selected!" visible={changeCurrentModal} setVisible={setChangeCurrentModal} proceedFunc={() => updateCurrent(botConfig._id).then((res)=>{window.location.reload();})}/>
            <WarnModal message={warnMessage} visible={warnModal} setVisible={setWarnModal} />
            <ProceedModal message="Would you like to save the new version?" visible={saveModal} setVisible={setSaveModal} proceedFunc={() => saveBot(botConfig, botList).then((res)=>{window.location.reload();})} />
        </div>
    );
}

export default GeneralInformation;
