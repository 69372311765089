import { useEffect, useState } from 'react';
import AppNav from '../nav/Navigation.js';
import { checkAuth, getTelemetry } from '../api.js'

import { useNavigate } from 'react-router-dom';

function Telemetry() {
    const [telemetry, setTelemetry] = useState([]);
    
    const navigate = useNavigate();

    useEffect(() => {
        if(!checkAuth()) {
            navigate("/");
            return;
        }

        getTelemetry().then((res) => {
            console.log(res);
            if(res['err'] !== undefined || res['client_err'] !== undefined) {
                localStorage.removeItem('auth');
                console.log("Failed to connect, this could be due to the API service restarting or the API service is online. Please check the status of the API.");
                navigate("/");
                return;
            }

            setTelemetry(res);

        });
    }, [navigate]);

    return (
        <div className="bg-theme-600 min-h-screen flex font-mono">
            <div className="sticky top-0 h-screen">
                <AppNav />
            </div>
            
            <div className="p-4 w-full overflow-y-visible">
                <h2 className="text-4xl text-white my-2">Telemetry</h2>
                {telemetry.map((tele) => (<>
                    <span className="text-white">{tele['user_agent']}</span>
                    <br />
                </>))}
            </div>
        </div>
    );
}

export default Telemetry;
