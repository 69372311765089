import { useState } from 'react';

function TreeEditor(params) {
    const { botConfig, setBotConfig, code } = params;  
    
    const [treeSelected, setTreeSelected] = useState(0);

    if(botConfig === undefined || Object.keys(botConfig).length === 0) {
        return (<><p>No bot configuration...</p></>);
    }

    const { tree } = botConfig;
    if(Object.keys(tree).length === 0)
        return (<></>);
    var selectedNode = Object.keys(tree)[treeSelected];
    if(tree[selectedNode] === undefined)
        selectedNode = Object.keys(tree)[0];
    const subOptions = tree[selectedNode]['options'];
    const subTree = tree[selectedNode]['subtree'];

    /** New Handlers **/

    const newSubNode = (ev) => {
        const subnode = {question: '', options: []}
        const keys = Object.keys(subTree);
        var newTree = {};
        for(var i= 1; i < 99; i++) {
            if(keys.includes("sub_"+i))
                continue;

            const newSubTree = {...subTree};
            newSubTree["sub_"+i] = subnode;
            newTree[selectedNode] = {'prompt': tree[selectedNode]['prompt'], 'options': subOptions, 'subtree': newSubTree};
            setBotConfig((prev) => ({...prev, tree: {...prev.tree, ...newTree}}));
            break;
        }
    };

    const newParentResponse = (ev) => {
        const newRes = {text: '', leads: '', freedom: false};
        tree[selectedNode]['options'].push(newRes);
        setBotConfig((prev) => ({...prev, tree: tree}));
    };

    const newSubNodeResponse = (ev) => {
        const name = ev.target.name.substring(ev.target.name.indexOf("-")+1);
        const newRes = {text: '', leads: '', freedom: false};

        tree[selectedNode]['subtree'][name]['options'].push(newRes);
        setBotConfig((prev) => ({...prev, tree: tree}))
    };

    const newSubOptions = (subOptions, indexEdit, name, ev) => {
        var index = 0;
        return subOptions.map((item) => {
            if(index === indexEdit){
                console.log(index);
                console.log(indexEdit);
                index++;
                var copy = {...item};
                copy[name] = ev.target.value;
                return copy;
            }
            index++;
            return item;
        }); 
    };

    /** Content Update Handlers **/

    const updateSelectedTree = (ev) => {
        setTreeSelected(ev.target.value);
    };

    const updateSubQuestion = (ev) => {
        const name = ev.target.name.substring(ev.target.name.indexOf("-")+1);
        tree[selectedNode]['subtree'][name]['question'] = ev.target.value;
        setBotConfig((prev) => ({...prev, tree: tree}));
    };

    const updateParentNode = (ev) => {
        const name = ev.target.name;
        tree[selectedNode][name] = ev.target.value;
        setBotConfig((prev) => ({...prev, tree: tree}));
    }; 

    const updateOption = (ev) => {
        const name = ev.target.name.substring(0, ev.target.name.indexOf("-"));
        const indexEdit = parseInt(ev.target.name.substring(ev.target.name.indexOf("-")+1));

        var newTree = {};
        newTree[selectedNode] = {'prompt': tree[selectedNode]['prompt'], 'options': newSubOptions(subOptions, indexEdit, name, ev), 'subtree': subTree};
        setBotConfig((prev) => ({...prev, tree: {...prev.tree, ...newTree}}));
    };

    const updateSubOption = (ev) => {
        const name = ev.target.name.substring(0, ev.target.name.indexOf("-"));
        const isub = ev.target.name.substring(ev.target.name.indexOf("-")+1);
        const indexEdit = parseInt(isub.substring(isub.indexOf("-")+1));
        const subNode = isub.substring(0, isub.indexOf("-"));

        tree[selectedNode]['subtree'][subNode]['options'][indexEdit][name] = ev.target.value;
        setBotConfig((prev) => ({...prev, tree: tree}));
    };

    const updateSubNode = (ev) => {
        const newName = ev.target.value;
        if(newName.includes("-")) // TODO: WARNING: This is because we parse the name new feature potential custom elements
        {
            return;
        }
        const oldName = ev.target.name.substring(ev.target.name.indexOf('-')+1);

        var newSubTree = {};
        Object.keys(subTree).forEach((s) => {
            if(s === oldName)
                newSubTree[newName] = subTree[oldName];
            else
                newSubTree[s] = subTree[s];
        });
        var newTree = {};
        newTree[selectedNode] = {...tree[selectedNode], 'subtree': newSubTree};
        setBotConfig((prev) => ({...prev, tree: {...prev.tree, ...newTree}}))
    };

    /** Delete Handlers **/

    const delSubOption = (ev) => {
        const subNode = ev.target.name.substring(0, ev.target.name.indexOf("-"));
        const indexEdit = parseInt(ev.target.name.substring(ev.target.name.indexOf("-")+1));

        tree[selectedNode]['subtree'][subNode]['options'].splice(indexEdit, 1);
        setBotConfig((prev) => ({...prev, tree: tree}));
    };

    const delOption = (ev) => {
        const indexEdit = ev.target.name;
        tree[selectedNode]['options'].splice(indexEdit, 1);
        setBotConfig((prev) => ({...prev, tree: tree}));
    }

    const delSubNode = (ev) => {
        const name = ev.target.name;
        delete tree[selectedNode]['subtree'][name];
        setBotConfig((prev) => ({...prev, tree: tree}));
    };

    /** Toggle Handlers **/
    const toggleOptionMod = (ev) => {
        const targ = ev.target.getAttribute("name");
        const name = targ.substring(0, targ.indexOf("-"));
        const indexEdit = parseInt(targ.substring(targ.indexOf("-")+1));

        tree[selectedNode]['options'][indexEdit][name] = !(tree[selectedNode]['options'][indexEdit][name]);
        setBotConfig((prev) => ({...prev, tree: tree}));
    };

    const toggleSubOptionMod = (ev) => {
        const targ = ev.target.getAttribute("name");
        const name = targ.substring(0, targ.indexOf("-"));
        const isub = targ.substring(targ.indexOf("-")+1);
        const indexEdit = parseInt(isub.substring(isub.indexOf("-")+1));
        const subNode = isub.substring(0, isub.indexOf("-"));

        tree[selectedNode]['subtree'][subNode]['options'][indexEdit][name] = !(tree[selectedNode]['subtree'][subNode]['options'][indexEdit][name]);
        setBotConfig((prev) => ({...prev, tree: tree}));
    };

    return (<>
        { code ? <></> : tree[selectedNode] === undefined ? <></> : 
        <div className="text-theme-100">
            <h2 className="text-2xl my-2">Tree Editor</h2> 
            <div className="flex flex-col">
                <div>
                    <label htmlFor="tree-edit">Node</label>
                    <select className="bg-theme-400 p-1 mx-4" name="tree-edit" value={treeSelected} onChange={updateSelectedTree}>
                        {Object.keys(tree).map((treeNode, index) => (
                            <option key={treeNode} value={index}>{treeNode}</option>
                        ))}
                    </select>
                </div>
                <label htmlFor="prompt">Prompt</label>
                <textarea rows="4" className="resize-none block w-full px-1 my-2 bg-theme-400" value={tree[selectedNode]['prompt']} name="prompt" onChange={updateParentNode}></textarea>
                <label htmlFor="question">Question</label>
                <textarea rows="4" className="resize-none block w-full px-1 my-2 bg-theme-400" value={tree[selectedNode]['question']} name="question" onChange={updateParentNode}></textarea>
                <div className="ml-10">
                    <h3>Response Options</h3>
                    {subOptions.map((option, index) => (
                        <div className="flex space-x-4 my-2" key={'suboptkey-'}>
                            <label htmlFor={'text-'+option.leads}>Text</label>
                            <input className="px-1 bg-theme-400 w-1/3" type="text" name={'text-'+index} value={option.text} onChange={updateOption} />
                            <label htmlFor={'leads-'+option.leads}>Leads</label>
                            <input className="px-1 bg-theme-400" type="text" name={'leads-'+index} value={option.leads} onChange={updateOption} />
                            
                            <>
                                <label name={'freedom-'+index}>Freedom</label>
                                <label name={'freedom-'+index} className="w-5 h-5 bg-theme-400 text-center select-none" onClick={toggleOptionMod}>
                                    {option['freedom'] !== undefined && option['freedom'] === true ? '✔' : ''}
                                </label>
                            </> 

                            <>
                                <button className="bg-red-800 hover:bg-red-900 px-4" name={index} onClick={delOption}>Del</button>
                            </>
                        </div>
                    ))}
                    <button className="bg-emerald-600 px-4 pt-1" onClick={newParentResponse}>Add New</button> 
                </div>
                <div>
                    <h3 className="text-xl my-2">Sub-Nodes</h3>
                    {Object.keys(subTree).map((sub, index) => (
                        <div className="ml-5 mt-5" key={'subkey-'+index}>
                            <div className="flex space-x-4 my-1">
                                <label htmlFor={'subnode-'+sub}>Node Name</label>
                                <input className="px-1 bg-theme-400" name={'subnode-'+sub} value={sub} onChange={updateSubNode} />
                                <button className="px-4 bg-red-800 hover:bg-red-900" name={sub} onClick={delSubNode}>Del Sub-Node</button>
                            </div>
                            <div className="ml-3">
                                <label htmlFor={'subnode-'+sub+'-q'}>Question</label>
                                <textarea rows="3" className="resize-none block w-full px-1 my-2 bg-theme-400" name={'subques-'+sub} value={subTree[sub]['question']} onChange={updateSubQuestion}/>
                                <h3>Questions</h3>
                                <div className="my-1 ml-3">
                                    {subTree[sub]['options'].map((opt, index) => (
                                        <div className="flex space-x-4 mt-2">
                                            <label htmlFor={'subnode-'+sub+'-'+index}>Text</label>
                                            <input type="text" className="px-1 bg-theme-400 w-1/3" name={'text-'+sub+'-'+index} value={opt['text']} onChange={updateSubOption} />
                                            { opt['prompt'] !== true ?
                                                <>
                                                    <label name={'subnode-'+sub+'-'+index+'-leads'}>Leads</label>
                                                    <input type="text" className="px-1 bg-theme-400" name={'leads-'+sub+'-'+index+''} value={opt['leads']} onChange={updateSubOption}/>
                                                </>
                                                : <></>
                                            }
                                            <>
                                                <label name={'subnode-'+sub+'-'+index+'-prompt'}>Prompt</label>
                                                <label name={'prompt-'+sub+'-'+index} className="w-5 h-5 bg-theme-400 text-center select-none" onClick={toggleSubOptionMod}>
                                                    {opt['prompt'] !== undefined && opt['prompt'] === true ? '✔' : ''}
                                                </label> 
                                            </>
                                            <>
                                                <label name={'subnode-'+sub+'-'+index+'-freedom'}>Freedom</label>
                                                <label name={'freedom-'+sub+'-'+index} className="w-5 h-5 bg-theme-400 text-center select-none" onClick={toggleSubOptionMod}>
                                                    {opt['freedom'] !== undefined && opt['freedom'] === true ? '✔' : ''}
                                                </label>
                                            </>
                                            <>
                                                <button className="bg-red-800 px-4 hover:bg-red-900" name={sub+'-'+index} onClick={delSubOption}>Del</button>
                                            </>
                                        </div>
                                    ))}
                                    <button className="bg-emerald-600 px-4 pt-1 mt-2 hover:bg-emerald-500" onClick={newSubNodeResponse} name={'click-'+sub}>Add New</button>
                                </div>
                            </div>
                            <hr className="bg-theme-100 mt-6" />
                        </div>
                    ))}
                    <button className="bg-emerald-700 px-4 pt-1 mt-2 hover:bg-emerald-600" onClick={newSubNode}>Add New Sub-Node</button>
                </div>
            </div>
        </div>}</>
    );
}


export default TreeEditor;
